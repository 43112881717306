import { HttpErrorResponse } from '@angular/common/http'
import { createAction, props } from '@ngrx/store'
import {
   GVOCommentEntity,
   GVOContactResponsibleRequest,
   GVOPartnerEntity,
   GVOResponsibleEntity,
   GVOResult,
} from '../../../features/feature-linking-tool/grant-vs-org/models/gvo-result.interface'
import { GVOSearchFields } from '../../../features/feature-linking-tool/grant-vs-org/models/gvo-search-fields.interface'
import { MPIResult } from '../../../features/feature-linking-tool/manage-proposed-ideas/models/mpi-result.interface'
import { PSPResult } from '../../../features/feature-linking-tool/proj-seeking-partners/models/psp-result'
import { BasicResult } from '../../models/interfaces/basic-result.interface'
import { BasicSearchFields } from '../../models/interfaces/basic-search-fields.interface'

/* Grant vs orgranisation */
export const openGVOSearch = createAction('[f-linking-tool] Open Grants vs Organisations search')

export const closeGVOSearch = createAction('[f-linking-tool] Close Grants vs Organisations search')

export const toggleGVOSearch = createAction('[f-linking-tool] Toggle Grants vs Organisations search')

export const searchGVOData = createAction('[f-linking-tool] Grants vs Organisations SEARCHING data', props<{ searchFields: GVOSearchFields }>())

export const searchGVODataSuccess = createAction('[f-linking-tool] Grants vs Organisations SEARCHING data SUCCESS', props<{ data: BasicResult<GVOResult> }>())

export const searchGVODataFailure = createAction('[f-linking-tool] Grants vs Organisations SEARCHING data FAILURE', props<{ error: HttpErrorResponse }>())

export const downloadGVOData = createAction('[f-linking-tool] Grants vs Organisations DOWNLOADING data', props<{ searchFields: GVOSearchFields }>())

export const downloadGVODataSuccess = createAction('[f-linking-tool] Grants vs Organisations DOWNLOADING data SUCCESS')

export const downloadGVODataFailure = createAction('[f-linking-tool] Grants vs Organisations DOWNLOADING data FAILURE', props<{ error: HttpErrorResponse }>())

export const addGVOComment = createAction('[f-linking-tool] Grants vs Organisations - adding comment', props<{ request: Partial<GVOCommentEntity> }>())

export const addGVOCommentSuccess = createAction('[f-linking-tool] Grants vs Organisations - adding comment SUCCESS')

export const addGVOCommentFailure = createAction('[f-linking-tool] Grants vs Organisations - adding comment FAILURE', props<{ error: HttpErrorResponse }>())

export const deleteGVOComment = createAction('[f-linking-tool] Grants vs Organisations - deleting comment', props<{ request: Partial<GVOCommentEntity> }>())

export const deleteGVOCommentSuccess = createAction('[f-linking-tool] Grants vs Organisations - deleting comment SUCCESS')

export const deleteGVOCommentFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting comment FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const addGVOLeadingEntity = createAction(
   '[f-linking-tool] Grants vs Organisations - adding potential leading entity',
   props<{ request: Partial<GVOPartnerEntity> }>()
)

export const addGVOLeadingEntitySuccess = createAction('[f-linking-tool] Grants vs Organisations - adding potential leading entity SUCCESS')

export const addGVOLeadingEntityFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - adding potential leading entity FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const deleteGVOLeadingEntity = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting potential leading entity',
   props<{ request: Partial<GVOPartnerEntity> }>()
)

export const deleteGVOLeadingEntitySuccess = createAction('[f-linking-tool] Grants vs Organisations - deleting potential leading entity SUCCESS')

export const deleteGVOLeadingEntityFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting potential leading entity FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const addGVOPartner = createAction('[f-linking-tool] Grants vs Organisations - adding partner entity', props<{ request: Partial<GVOPartnerEntity> }>())

export const addGVOPartnerSuccess = createAction('[f-linking-tool] Grants vs Organisations - adding partner SUCCESS')

export const addGVOPartnerFailure = createAction('[f-linking-tool] Grants vs Organisations - adding partner FAILURE', props<{ error: HttpErrorResponse }>())

export const deleteGVOPartner = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting partner entity',
   props<{ request: Partial<GVOPartnerEntity> }>()
)

export const deleteGVOPartnerSuccess = createAction('[f-linking-tool] Grants vs Organisations - deleting partner SUCCESS')

export const deleteGVOPartnerFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting partner FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const addGVOResponsible = createAction(
   '[f-linking-tool] Grants vs Organisations - adding responsible',
   props<{ request: Partial<GVOResponsibleEntity> }>()
)

export const addGVOResponsibleSuccess = createAction('[f-linking-tool] Grants vs Organisations - adding responsible SUCCESS')

export const addGVOResponsibleFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - adding responsible FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const addGVOPNOResponsible = createAction('[f-linking-tool] Grants vs Organisations - adding PNO responsible', props<{ matchmakingId: string }>())

export const addGVOPNOResponsibleSuccess = createAction('[f-linking-tool] Grants vs Organisations - adding PNO responsible SUCCESS')

export const addGVOPNOResponsibleFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - adding PNO responsible FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const deleteGVOResponsible = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting responsible entity',
   props<{ request: Partial<GVOResponsibleEntity> }>()
)

export const deleteGVOResponsibleSuccess = createAction('[f-linking-tool] Grants vs Organisations - deleting responsible SUCCESS')

export const deleteGVOResponsibleFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - deleting responsible FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const contactGVOResponsible = createAction(
   '[f-linking-tool] Grants vs Organisations - contacting responsible',
   props<{ grantId: number; requst: GVOContactResponsibleRequest }>()
)

export const contactGVOResponsibleSuccess = createAction('[f-linking-tool] Grants vs Organisations - contacting responsible SUCCESS')

export const contactGVOResponsibleFailure = createAction(
   '[f-linking-tool] Grants vs Organisations - contacting responsible FAILURE',
   props<{ error: HttpErrorResponse }>()
)

export const resetGVOState = createAction('[f-linking-tool] Grants vs Organisations  - RESET DATA')

/* Manage proposed ideas */
export const setMPIDisplayLayoutList = createAction('[f-linking-tool] Manage proposed ideas - display layout: LIST')

export const setMPIDisplayLayoutGrid = createAction('[f-linking-tool] Manage proposed ideas - display layout: GRID')

export const setMPISearchFields = createAction('[f-linking-tool] Manage proposed ideas - SETTING search fields', props<{ searchFields: BasicSearchFields }>())

export const getMPIData = createAction('[f-linking-tool] Manage proposed ideas - GETTING data', props<{ request: BasicSearchFields }>())

export const getMPIDataSuccess = createAction('[f-linking-tool] Manage proposed ideas - GETTING data SUCCESS', props<{ data: BasicResult<MPIResult> }>())

export const getMPIDataFailure = createAction('[f-linking-tool] Manage proposed ideas - GETTING data FAILURE', props<{ error: HttpErrorResponse }>())

export const markMPIDataAsProcessed = createAction('[f-linking-tool] Manage proposed ideas - marking data as processed', props<{ id: number }>())

export const markMPIDataAsProcessedSuccess = createAction('[f-linking-tool] Manage proposed ideas - marking data as processed SUCCESS')

export const markMPIDataAsProcessedFailure = createAction(
   '[f-linking-tool] Manage proposed ideas - marking data as processed FAILED',
   props<{ error: HttpErrorResponse }>()
)

export const deleteMPIData = createAction('[f-linking-tool] Manage proposed ideas - deleting item', props<{ id: number }>())

export const deleteMPIDataSuccess = createAction('[f-linking-tool] Manage proposed ideas - deleting item SUCCESS')

export const deleteMPIDataFailure = createAction('[f-linking-tool] Manage proposed ideas - deleting item FAILURE', props<{ error: HttpErrorResponse }>())

export const resetMPIState = createAction('[f-linking-tool] Manage proposed ideas - RESET DATA')

/* Projects seeking partners */
export const setPSPDisplayLayoutList = createAction('[f-linking-tool] Project seeking partners - display layout: LIST')

export const setPSPDisplayLayoutGrid = createAction('[f-linking-tool] Project seeking partners  - display layout: GRID')

export const getPSPData = createAction('[f-linking-tool] Project seeking partners - GETTING data', props<{ request: BasicSearchFields }>())

export const getPSPDataSuccess = createAction('[f-linking-tool] Project seeking partners - GETTING data SUCCESS', props<{ data: BasicResult<PSPResult> }>())

export const getPSPDataFailure = createAction('[f-linking-tool] Project seeking partners - GETTING data FAILURE', props<{ error: HttpErrorResponse }>())

export const resetPSPState = createAction('[f-linking-tool] Project seeking partners  - RESET DATA')
